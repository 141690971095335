import React from "react";
import ContentError from "./contenterror";

function Signin() {
    return (
        <div>
            <ContentError />
        </div>
    )
};

export default Signin;