import React from "react";

function Careers() {
    return (
        <div className="careers">
            <h1>We are Hiring</h1>
            <p>
                Coupid is at it's start up stage and is looking for various talents to join us!
                If you believe you are a good fit, feel free to send us your resume.

                We are keen to get more tech developers on board, as well as marketing experts to join us!

            </p>
        </div>
    )
}
export default Careers;