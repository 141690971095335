import React from "react";

function Legal() {
    return (
        <div className="legal">
            <h1>Legal and Privacy</h1>
            <p>
                blah blah blah
            </p>
        </div>
    )
};

export default Legal;