import React from "react";

function Tutorial() {

    return (
        <div className="tutorial">
            <h1>How to Get Started?</h1>
            <p>
            Gap filler.  
            </p>
        </div>

    )
};

export default Tutorial;