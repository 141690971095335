import React from "react";

function TandC() {
    return (
        <div className= "termsandcondition">
            <h1>COUPID Terms and Condition</h1>
            <p>
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants. <br/>
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.
                This is a mock T&Cs page where we will put down our terms and condition for merchants.

            </p>
        </div>
    )
};

export default TandC;