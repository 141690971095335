import React from "react";
import ContentError from "./contenterror";

function BrowseGifts() {
    return(
        <div>
            <ContentError />
        </div>
    )
}

export default BrowseGifts;